import React, { useState } from 'react'
import classNames from "classnames"

import WorkListStyles from "./WorkList.module.css"

import upArrow from "../assets/icons/circle-up-solid.svg";
import downArrow from "../assets/icons/circle-down-solid.svg";

import lagerkungenImage from "../assets/images/work/lagerkungen.png"
import digiprocImage from "../assets/images/work/digiproc.png"
import dbmImage from "../assets/images/work/dbm.png"

import reactIcon from "../assets/icons/techniques/react.svg";
import wordpressIcon from "../assets/icons/techniques/wordpress.svg";


export default function WorkList() {

    const [slideCount, setSlideCount] = useState(0);

    let workArray = [
        {
            title : "Lagerkungen",
            description : "Lagerkungen is the first fully automated, and remote, self-storage solution in Sweden. In this project I created a multitude of different components and Ui's that helped expand the website. I was also often in charge of making the mobile versions of the designs I received.",
            image : lagerkungenImage,
            techniques: [
                { name: "React", icon: reactIcon},
                { name: "WordPress", icon: wordpressIcon}
            ],
            link : "https://lagerkungen.se"
        },
        {
            title : "Digiproc",
            description : "Digiproc is an automatic platform for long tail negotiations of purchase prices. Helping both big and small suppliers negotiate with costumers for the best price points. In this project I worked mainly with creating fully interactive and dynamic graphs using D3.js using massive sets of data.",
            image : digiprocImage,
            techniques: [
                { name: "React", icon: reactIcon},
                { name: "WordPress", icon: wordpressIcon}
            ],
            link : "https://www2.digiproc.com"
        },
        {
            title : "Developed By Me",
            description : "My previous employer's own website, showcasing the work they do and the philosophy behind it. I built the majority of the site by myself. Most of the images and graphical elements on the site are made using an integrated editor I made using Konva.js.",
            image : dbmImage,
            techniques: [
                { name: "React", icon: reactIcon},
                { name: "WordPress", icon: wordpressIcon}
            ],
            link : "https://developedbyme.com"
        }
    ];

    let upBtnClass = classNames(WorkListStyles.slideButton, { [WorkListStyles.hide]: slideCount === 0});
    let downBtnClass = classNames(WorkListStyles.slideButton, { [WorkListStyles.hide]: slideCount === workArray.length -1});
    let slideControlsDesktop = classNames(WorkListStyles.slideControls, WorkListStyles.desktop);
    let slideControlsMobile = classNames(WorkListStyles.slideControls, WorkListStyles.mobile);

    function slideUp() {

        if (slideCount !== 0) {
            setSlideCount(slideCount - 1);
        }

    }

    function slideDown() {
        if (slideCount < workArray.length - 1) {
            setSlideCount(slideCount + 1);
        }
    }

  return (
    <section id="PreviousWork" className={WorkListStyles.container}>
        <h2 className={WorkListStyles.heading}>Previous Work</h2>
        <div className={WorkListStyles.slideContainer}>
            {workArray.map((item, index) => {
                if (index === slideCount) {
                    return (
                        <div key={index}>
                            <div className="position-relative">
                                <div className={slideControlsDesktop}>
                                    <button className={upBtnClass} style={{color: "black"}} onClick={() => slideUp()}>
                                        <img src={upArrow} className={WorkListStyles.arrowIcon} alt="Previous Slide"/>
                                    </button>
                                    
                                    <button className={downBtnClass} style={{color: "black"}} onClick={() => slideDown()}>
                                        <img src={downArrow} className={WorkListStyles.arrowIcon} alt="Next Slide"/>
                                    </button>
                                </div>
                                <img src={item.image} className={WorkListStyles.image} alt={item.title}/>
                            </div>
                            <div className={slideControlsMobile}>
                                <button className={upBtnClass} style={{color: "black"}} onClick={() => slideUp()}>
                                    <img src={upArrow} className={WorkListStyles.arrowIcon} alt="Previous Slide"/>
                                </button>
                                
                                <button className={downBtnClass} style={{color: "black"}} onClick={() => slideDown()}>
                                    <img src={downArrow} className={WorkListStyles.arrowIcon} alt="Next Slide"/>
                                </button>
                            </div>
                            <div className={WorkListStyles.textContainer}>
                                <div className={WorkListStyles.iconContainer}>
                                    <h3 className={WorkListStyles.workTitle}>
                                        {item.title}
                                    </h3>
                                    {
                                        item.techniques.map((item, index) => {
                                            return <img className={WorkListStyles.techniqueIcon} src={item.icon} alt={item.name} key={index} />
                                        })
                                    }
                                </div>
                                <p className={WorkListStyles.description}>
                                {item.description}
                                </p>
                            </div>
                            <a className={WorkListStyles.link} href={item.link} target="_blank" rel="noreferrer" >
                                Visit site
                            </a>
                        </div>
                    )
                } else return null;
            })   
            }
        </div>
    </section>
  )
}
