import React from "react";
import classNames from "classnames"

import hero from "../assets/images/hero-me.png";
import HeroStyles from "./Hero.module.css";

export default function Hero() {

    let secondSubText = classNames(HeroStyles.subText, HeroStyles.second);

    return (
        <div className={HeroStyles.heroContainer}>
            <div className={HeroStyles.text}>
                <div className={HeroStyles.subText}>Hello, my name is</div>
                <h1 className={HeroStyles.heading}>Elliot Blomqvist</h1>
                <div className={secondSubText}>I help create inspiring web solutions with a focus on UI and frontend </div>
            </div>
            <img src={hero} className={HeroStyles.img} alt="headshot" />
            <div className={HeroStyles.line} />
        </div>
    );
}
