import React from 'react'

import AboutMeStyles from "./AboutMe.module.css";

export default function AboutMe() {
  return (
    <div id="AboutMe" className={AboutMeStyles.container}>
        <div className={AboutMeStyles.colorWrapper}>
            <section className={AboutMeStyles.textContainer}>
              <h2 className={AboutMeStyles.heading}>About Me</h2>
              <p className={AboutMeStyles.description}>
              I’m a Swedish frontend engineer based in Stockholm that loves snappy user-interfaces, bold new features and everything CSS. Since graduating from Medieinsitutet in 2023 I have dedicated myself to learn everything that makes the web tick. My main goal is to be able to create any experience on the web, big or small. Being able to express myself in my work, from the code I write, the designs I help make and the people I work with along the way. I always like to put my own little spin on things, when there’s room for it. I’m currently looking for my next opportunity, so if you're interested in working with me, or have any other questions, contact me on my <a href="https://www.linkedin.com/in/elliot-blomqvist-04946516a/" target="_blank" rel="noreferrer" className={AboutMeStyles.linkedInLink}>LinkedIn</a>. <br /> <br /> Let’s make something together!
              </p>
            </section>
        </div>
    </div>
  )
}