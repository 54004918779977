import React from "react";

import HeaderStyles from "./Header.module.css";

export default function Header() {

    return <header className={HeaderStyles.header}>
        <div className="initials">E/B</div>
        <nav className={HeaderStyles.nav}>
            <a className={HeaderStyles.link} href="#AboutMe">About Me</a>
            <a className={HeaderStyles.link} href="#PreviousWork">Previous Work</a>
        </nav>
    </header>;
}
