import React, {useState} from 'react'

import githubIcon from "../assets/icons/social/github-mark-white.svg";
import githubLogotype from "../assets/icons/social/github-wordmark-white.svg";
import linkedinIcon from "../assets/icons/social/LI-In-Bug.png";
import linkedinLogotype from "../assets/icons/social/LI-Logo.png";
import FooterStyles from "./Footer.module.css";

export default function Footer() {

    const [animateGitHub, setAnimateGitHub] = useState(false);
    const [animateLinkedIn, setAnimateLinkedIn] = useState(false);

    function toggleGitHubAnimation() {
        setAnimateGitHub(prevAnimateGitHub => !prevAnimateGitHub);
    };

    function toggleLinkedInAnimation() {
        setAnimateLinkedIn(prevAnimateLinkedIn => !prevAnimateLinkedIn);
    };

  return (
    <footer className={FooterStyles.footer}>
        <div className="initials">E/B</div>
        <nav className={FooterStyles.nav}>
            <a href="https://www.linkedin.com/in/elliot-blomqvist-04946516a/" target="_blank" rel="noreferrer" className={FooterStyles.link}>
                {animateLinkedIn ? 
                    <img src={linkedinLogotype} className={FooterStyles.linkedinLogotypeActive} alt="linkedin-logotype"/>
                    :
                    <img src={linkedinLogotype} className={FooterStyles.linkedinLogotype} alt="linkedin-logotype"/>
                }
                <img src={linkedinIcon} className={FooterStyles.linkedinIcon} alt="linkedin-icon" onMouseOver={() => toggleLinkedInAnimation()} onMouseLeave={() => toggleLinkedInAnimation()}/>
            </a>
            <a href="https://github.com/elgen98" target="_blank" rel="noreferrer" className={FooterStyles.link}>
                <img src={githubIcon} className={FooterStyles.githubIcon} alt="github-icon" onMouseOver={() => toggleGitHubAnimation()} onMouseLeave={() => toggleGitHubAnimation()}/>
                {animateGitHub ? 
                    <img src={githubLogotype} className={FooterStyles.githubLogotypeActive} alt="github-logotype"/>
                    :
                    <img src={githubLogotype} className={FooterStyles.githubLogotype} alt="github-logotype"/>
                }
            </a>
        </nav>
        <div className="initials hidden-initials">E/B</div>
    </footer>
  )
}
