import "./App.css";
import AboutMe from "./components/AboutMe";
import Footer from "./components/Footer";
import Header from "./components/Header";
import Hero from "./components/Hero";
import WorkList from "./components/WorkList";

export default function App() {
    return (
        <div className="App">
            <div className="viewport-div">
                <Header />
                <Hero />
            </div>
            <AboutMe />
            <WorkList />
            <Footer />
        </div>
    );
}